<template>
  <v-container id="dashboard" fluid tag="section">

    <base-material-card icon="mdi-cart">
      <slot slot="title">All Orders</slot>
      <slot slot="rightButtons">
        <v-row>
          <v-text-field label="Search Orders" v-model="match" :rules="[utils.requiredRule]"/>
          <v-btn class="mt-2" elevation="1" fab small @click="search">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <toggle-button class="mt-2 mr-2 ml-2" tooltip="Add a new Order" openIcon="mdi-plus" @toggle="showCreateOrderToggle = !showCreateOrderToggle" :showing="showCreateOrderToggle"/>
        </v-row>
      </slot>
        <div v-if="showCreateOrderToggle">
          <v-form ref="order">
            <v-row>
              <v-col cols="12">
                <v-text-field label="New Order Name" v-model="newOrderName" :rules="[utils.requiredRule]">
                  <template v-slot:append-outer>
                    <v-icon large @click="createOrder">mdi-checkbox-marked-circle</v-icon>
                    <v-icon large @click="showCreateOrderToggle = false">mdi-close-circle</v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </div>
      <span v-if="$vuetify.breakpoint.smAndUp">
        <v-row class="ml-n1 mr-n1">
          <v-col cols="2">Order Number</v-col>
          <v-col cols="3">Name</v-col>
          <v-col cols="2">Date</v-col>
          <v-col cols="3">Email</v-col>
          <v-col cols="1">Status</v-col>
          <v-col cols="1">Total</v-col>
        </v-row>
        <v-row>
          <v-col class="clickableRow">
            <v-row class="mx-n1 underlinedRow" v-for="(order, i) in orders" :key="i" @click="selectItem(order)">
              <v-col cols="2">{{order.orderNumber}}</v-col>
              <v-col cols="3">{{order.name}}</v-col>
              <v-col cols="2">{{utils.dateToDdMmYyString(order.created)}}</v-col>
              <v-col cols="3">{{order.email}}</v-col>
              <v-col cols="1">{{order.status}}</v-col>
              <v-col cols="1">{{utils.priceDisplay(order.totalCost, order.currency)}}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </span>
      <span v-else>
        <v-row class="ml-n1 mr-n1">
          <v-col cols="3">Order</v-col>
          <v-col cols="4">Name</v-col>
          <v-col cols="3">Date</v-col>
          <v-col cols="2">Status</v-col>
        </v-row>
        <v-row>
          <v-col class="clickableRow">
            <v-row  class="mx-n1 underlinedRow" v-for="(order, i) in orders" :key="i" @click="selectItem(order)">
              <v-col cols="4">{{order.orderNumber}}</v-col>
              <v-col cols="4">{{order.name}}</v-col>
              <v-col cols="3">{{order.email}}</v-col>
              <v-col cols="2">{{order.status}}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </span>
    </base-material-card>
  </v-container>
</template>
  
<script>
import Api from '@/services/Api.js'; 
import Utils from '@/services/Utils.js';
import Store from '@/services/Store.js';
import { getCurrencyPrefix } from '@/helpers/currency'

  export default {
    name: 'BoxOffice',

    components: {
      BaseMaterialCard: () => import('@/components/base/MaterialCard'),
      ToggleButton: () => import('./components/ToggleButton'),
    },  
    
    computed: {
      utils: function () {
        return Utils;
      }
    },

    data () {
      return {
        getCurrencyPrefix,
        match: "",
        newOrderName: "",
        boxOffice: {},
        orders: [],
        showCreateOrderToggle: false,
      }
    },

    created() {
      this.boxOffice.id = this.$route.params.id;
      this.load();
    },
    
    methods: {
      selectItem(order) {
        this.$router.push("/Dashboard/Order/" + order.id)
      },

      async load() {
        this.boxOffice = await Api.post(this, "BoxOffice", "read", this.boxOffice);
        Store.store(this.boxOffice);
        this.search();
      },

      async search() {
        var request = {}
        request.boxOffice = this.boxOffice;
        request.match = this.match;
        this.orders = await Api.post(this, "Order", "search", request);
      },

      async createOrder() {
        if (! await Utils.validate(this.$refs.order) ) {
          return;
        }
        var order = {}
        order.name = this.newOrderName;
        order.boxOfficeId = this.boxOffice.id;
        await Api.post(this, "Order", "create", order);
        this.newOrderName = "";
        this.showCreateOrderToggle = false;
        this.search();
      },
    }
  }

</script>
